<template>
  <div>
    <a-form layout="vertical">
      <a-row :gutter="16">
        <a-col :span="10">
          <a-form-item label="Nama Insentif">
            <a-input v-model="dataInsentif.nama" size="large" disabled/>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item>
            <span slot="label">
              Tanggal Pembayaran &nbsp;
              <a-tooltip
                title="Tanggal pembayaran insentif ke pegawai"
              >
                <a-icon type="info-circle-o" />
              </a-tooltip>
            </span>
            <a-date-picker
              disabled
              size="large"
              v-model="dataInsentif.tanggalPembayaran"
              class="w-100"
            />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item>
            <span slot="label">
              Status Insentif &nbsp;
              <a-tooltip
                title="Status yang menandakan keadaan data insentif"
              >
                <a-icon type="info-circle-o" />
              </a-tooltip>
            </span>
            <a-input-number
              disabled
              size="large"
              v-model="dataInsentif.status"
              class="w-100"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item>
            <span slot="label">
              Total Insentif Untuk Semua Pegawai &nbsp;
              <a-tooltip
                title="Terapkan Nominal Insentif untuk semua Pegawai"
              > <a-icon type="info-circle-o" />
              </a-tooltip>
            </span>
            <a-input-number :placeholder="statusInsentif ? 'DATA SUDAH TERFINALISASI': 'Terapkan nominal insentif untuk semua pegawai'" :disabled="statusInsentif" class="w-100" size="large" :min="0" step="1" @change="(e) => handleChange(e, 'all', 'totalInsentif')" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="border rounded px-2 pt-4 m-0">
        <div class="mb-4 ml-auto d-flex justify-content-between">
          <h3 class="ml-3">Daftar Pegawai & Insentif</h3>
          <a-button
            v-model="dataInsentif.id"
            :disabled="statusInsentif"
            class="mr-3"
            icon="lock"
            size="large"
            @click.prevent="handleFinalisasiInsentif(dataInsentif.id)"
          > Finalisasi
          </a-button>
        </div>
        <a-col :span="24">
          <a-table
            :columns="intensifPegawaiColumns"
            :data-source="dataInsentifPegawai"
            bordered
            size="large"
          >
            <div v-if="!statusInsentif" slot="totalInsentif" slot-scope="value, record" >
              <a-input-number
                :value="value"
                :min="0"
                step="1"
                @change="(e) => handleChange(e, 'one', 'totalInsentif', record)"
                class="w-100"
              />
            </div>
            <div v-else slot="totalInsentif" slot-scope="value">
              {{ thousandPrice(value) }}
            </div>
          </a-table>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import thousandPrice from '@/helpers/thousandPrice'

export default {
  data() {
    return {
      thousandPrice,
    }
  },
  props: {
    dataInsentif: {
      type: Object,
      required: true,
    },
    intensifPegawaiColumns: {
      type: Array,
      required: true,
    },
    dataInsentifPegawai: {
      type: Array,
      required: true,
    },
    statusInsentif: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleFinalisasiInsentif(key) {
      this.$emit('handle-finalisasi', key)
    },
    handleChange(value, type, column, data) {
      const payload = {
        value,
        type,
        column,
        data,
      }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style>
</style>
